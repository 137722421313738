import {
  Link as ChakraLink,
  Text,
  Input,
  Box,
  Badge,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Select,
  Heading,
  Avatar,
  Grid,
  GridItem,
  Center,
  Divider,
  Spinner,
  Progress,
  Tooltip,
  Alert,
  AlertIcon,
  HStack,
  useToast,
  Flex,
  Spacer,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  VStack
} from '@chakra-ui/react'
import Head from 'next/head'
import { useSession, signIn, signOut, getSession } from "next-auth/react"
import { useRouter } from 'next/router'

import axios from 'axios';
import JSZip from "jszip";
import { saveAs } from "file-saver";

import exportFromJSON from 'export-from-json' 

import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";

import Link from 'next/link'
import { IoChevronDown, IoHelpCircle, IoHappy,IoShapes, IoEarth, IoMedkit, IoCar, IoBicycle,IoAddCircleOutline, IoSchool, IoShirt, IoPint, IoPizza, IoAtCircle, IoAirplane, IoHome, IoSubway, IoColorPalette, IoTv, IoFitness, IoPaw, IoMusicalNotes, IoSettings, IoExit, IoChevronForward, IoBriefcase, IoOpen, IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle, IoCheckbox, IoCheckmarkDoneCircle, IoReceipt, IoGlobe, IoRemove, IoRemoveCircle, IoChevronForwardOutline, IoChevronBack, IoColorWand, IoAlertCircleOutline, IoAlertCircle} from 'react-icons/io5';


import { Container } from '../components/Container'
import SubscriptionManager from '../components/SubscriptionManager'
import TravelExpensesManager from '../components/TravelExpensesManager'
import TravelExpenseListView from "../components/TravelExpenseListView"
import MenuElement from "../components/MenuElement"
import TutorialModal from '../components/TutorialModal';
import InvoiceDetailDrawer from '../components/InvoiceDetailDrawer';

import DeleteModal from '../components/DeleteModal';


import React, { useState, useRef, Component } from "react";
import { convertTransitionToAnimationOptions } from 'framer-motion/types/animation/utils/transitions';
import internal from 'stream';
import { open } from 'fs/promises';
import { create } from 'lodash';
import { stat } from 'fs';
import { throws } from 'assert';

import Board from 'react-trello'


import InfiniteScroll from 'react-infinite-scroller';

import { Card, ProgressBar } from "@tremor/react";


//import {PDFtoIMG} from 'react-pdf-to-image';
//import { Document, Page, pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import PdfThumbnail from 'react-pdf-thumbnail';
import { userAgent } from 'next/server';


import * as Scroll from 'react-scroll';
import {Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import AllIcons from '../constants/AllIcons';

const mainContainer= {maxWidth: "1200px", display: "block", margin: "auto"}


let CustomCard = (data) =>{

  let item = data;

  return (
    <Box padding="3" backgroundColor={"white"} borderWidth='1px' borderRadius='lg' mb="2" style={{transition: "all 0.2s"}}
    _hover={{
      cursor: "pointer",
      backgroundColor: "#f4f4f4"
    }}
    >
      <Heading  as='h4' size='sm'>
        {item.shop}
      </Heading>
      <Box display='flex' alignItems='baseline'>
        <Box
          color='gray.500'
          fontWeight='semibold'
          letterSpacing='wide'
          textTransform='uppercase'
          ml='0'
        >
          {item.date}
        </Box>
      </Box>
      <Heading  as='h4' size='sm'>
        {item.shop}
      </Heading>
    </Box>
  )
}

  class NewCardForm extends Component {
    //handleAdd = () => this.props.onAdd({title: this.titleRef.value, description: this.descRef.value})
    //setTitleRef = (ref) => this.titleRef = ref
    //setDescRef = (ref) => this.descRef = ref
    render() {
      //const {onCancel} = this.props
      return (
        <Box>
          <Input></Input>
        </Box>
      )
    }
  }

const menuWrapper = {
  maxWidth: "1240px",
  position: "relative" as "relative",
  display: "block",
  margin: "auto"
}

const Index = ({sessionServerSide, checkSubscription, tabIndexMenu}) => {
  const [rekeningData, setRekeningData] = useState([]);
  const [viewStyle, setViewStyle] = useState("Kanban");
  const toast = useToast()
  
  const [rekeningDataOriginal, setRekeningDataOriginal] = useState([]);
  const [travelExpenseData, setTravelExpenseData] = useState([]);

  const [budgetSpendingData, setBudgetSpendingData] = useState({spending: {}, totalSpending: 0.0})

  const [selectedInvoicesIds, setSelectedInvoicesIds] = useState([]);
  const [selectedInvoiceMonths, setSelectedInvoiceMonths] = useState([])


  const [invoiceTypesData, setInoviceTypesData] = useState([]);
  
  
  const [changedInvoices, setChangedInvoices] = useState([]);

  const [moreInvoicesAvailable, setMoreInvoicesAvailable] = useState(true);


  const [editInvoiceTypeWorkflow, setEditInvoiceTypeWorkflow] = useState({steps: [], title: ""});

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRefund, setIsLoadingRefund] = useState(false);

  const [isLoadingRequestRefund, setIsLoadingRequestRefund] = useState(false);
  

  const [editInvoiceReadonly, setEditInvoiceReadonly] = useState(false);
  const [editInvoiceReadonlyReason, setEditInvoiceReadonlyReason] = useState("This invoice is locked");
  const [editInvoiceModified, setEditInvoiceModified] = useState(false);
  
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [bulkUploadSettings, setBulkUploadSettings] = useState({category: "", invoiceType: ""});
  const [bulkUploadCounterFinished, setBulkUploadCounterFinished] = useState(0);
  const [bulkUploadCounterTotal, setBulkUploadCounterTotal] = useState(0);
  
  const [categoriesData, setCategoriesData] = useState([]);



  const [userData, setUserData] = useState([]);
  
  const [tabIndex, setTabIndex] = useState(parseInt(tabIndexMenu))
  

  //OLD
  const [searchForText, setSearchForText] = useState("");
  const [searchForStatus, setSearchForStatus] = useState("");
  const [searchForUser, setSearchForUser] = useState("");

  //NEW
  const [searchValues, setSearchValues] = useState([{key: "searchText", value: ""},{key: "searchUser", value: ""}]);
  
  const [totalVisibleValue, setTotalVisibleValue] = useState("0");

  

  const [selectedInvoice, setSelectedInvoice] = useState(0)
  const [selectedInvoiceNextStep, setSelectedInvoiceNextStep] = useState(undefined);

  const [loginRequired, setLoginRequired] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [viewImage, setViewImage] = React.useState();

  const [createInvoice, setCreateInvoice] = useState(false);
  const [createInvoiceType, setCreateInvoiceType] = useState("");
  


  const api_url = process.env.NEXT_PUBLIC_API_URL
  
  const { isOpen: isRefundOpen, onOpen: onRefundOpen, onClose: onRefundClose } = useDisclosure()
  const { isOpen: isRequestRefundOpen, onOpen: onRequestRefundOpen, onClose: onRequestRefundClose } = useDisclosure()
  
  const { isOpen: isDownloadOpen, onOpen: onDownloadOpen, onClose: onDownloadClose } = useDisclosure()

  const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure()
  
  const { isOpen: invoiceIsOpen, onOpen: invoiceOnOpen, onClose: invoiceOnClose } = useDisclosure()

  const { isOpen: isOpenBulkUploadPrep, onOpen: onOpenBulkUploadPrep, onClose: onCloseBulkUploadPrep } = useDisclosure()

  const { isOpen: isOpenBulkUpload, onOpen: onOpenBulkUpload, onClose: onCloseBulkUpload } = useDisclosure()

  //  Delete Confirm Modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);


  //Upload Duplicate
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [duplicateCurrentFile, setDuplicateCurrentFile] = useState(null);
  const [duplicateUserDecision, setDuplicateUserDecision] = useState(null);

  const handleDuplicateOverwrite = () => {
      duplicateUserDecision('overwrite');
  };

  const handleDuplicateSkip = () => {
      duplicateUserDecision('skip');
  };

  const emptyTravelExpense = {
    kind:"travel-expense",
    uuid:"",
    shop:"",
    description:"",
    invoiceStatus: "",
    category:"",
    tax: "0",
    mwst: "0",
    total: "",
    totalNet: "",
    currency: "",
    taxPositions:[],
    travelExpense:{
      locationFrom:"",
      locationTo:"",
      transportation:"train",
      transportationDistance:"",
      breakfasts:0,
      lunches:0,
      dinners:0,
      startDate:new Date().toUTCString(),
      endDate:new Date().toUTCString(),
      country: "DE",
      allowanceSmall: "14",
      allowanceBig: "28",
      compensation: "0",
    },
    author: "",
    authorData:[{name:""}],
    childInvoices:[],
};

  const [travelExpense,setTravelExpense] = useState(emptyTravelExpense);
  const [newTravelExpense,setNewTravelExpense] = useState(true);
  
  
  const [monthlyCost, setMonthlyCost] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [travelExpenseFilter, setTravelExpenseFilter] = useState([]);
  const [bulkInsertData, setBulkInsertData] = useState([]);
  
  const [travelExpenseLoadHasMore, setTravelExpenseLoadHasMore] = useState(true);


  const { isOpen: isTutorialOpen, onOpen: onTutorialOpen, onClose: onTutorialClose } = useDisclosure()
  
  

  //TRAVEL EXPENSE
  const { isOpen: isTravelExpenseOpen, onOpen: onTravelExpenseOpen, onClose: onTravelExpenseClose } = useDisclosure()


  //const { data: session, status} = useSession()
  const [session, setSession] = useState(sessionServerSide)
  
  const currency = "€"

  const { query } = useRouter();


  const router = useRouter();
  

  React.useEffect(() => {
    //loadInvoices();
    loadUser();
    loadCategories();
    if(tabIndex == 1){
      loadTravelExpenses();
    }else{

    }
    loadInvoiceTypes();
    if(checkSubscription == "true"){
      updateSubscription()
    }
  }, [query])

  React.useEffect(() => {
    setTabIndex(tabIndexMenu)
  },[tabIndexMenu])

  const updateSubscription = async () =>{
    
        
    const fetchData = await fetch(`${api_url}/teams/subscription/update`, {
      method: "POST", 
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json' 
      }   
    })
    const fetchDataJson = await fetchData.json();
    if("statusCode" in fetchDataJson){  
    }else{ 
        //window.location.href = fetchDataJson.url;
    } 
}
  const userNotification = async (title, subTitle, status) => {
    toast({
      title: title,
      description: subTitle,
      status: status,
      duration: 5000,
      isClosable: true,
      position: 'bottom-left',
    })
  }

  const loadUser = async () =>{
    //const session = await getSession();
    try{

      const fetchData = axios.get(`${api_url}/users`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const data = await fetchData;

      if(data.status != 403 && data.status != 500){
        setUserData(data.data);
        return data.data; 
      }
    }catch (ex){
      return
    }
  }
  const loadCategories = async () =>{
    //const session = await getSession();

    try{

      const fetchData = axios.get(`${api_url}/categories`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const data = await fetchData;

      if(data.status != 403 && data.status != 500){

        //ADD Web icon to category list for dispalying
        let categoryList = data.data;
        categoryList.map((element, i)=>{
          let displayIcon = AllIcons.find((e)=> e.iconWeb == element.iconWeb);
          categoryList[i]["iconWebElement"] = displayIcon.iconWebElement
        })

        //set data
        setCategoriesData(categoryList);
        
        return data.data; 
      }
    }catch (ex){
      return
    }
  }

  const loadBudgetSpending = async (categoryId) =>{

    if(categoryId == undefined){
      return
    }

    try{
      const fetchDataCategory = axios.get(`${api_url}/invoices/spendingPerCategory/${categoryId}`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const dataCategory = await fetchDataCategory;
      
      if(dataCategory.status != 403 && dataCategory.status != 500){
        setBudgetSpendingData({...dataCategory.data});
      }
    }catch (ex){
      return
    }
  }

  const calculateMonthlyCost = async(data)=>{
    let existingInvoices = rekeningData;
    let totalCostTemp = 0.0;

    if(data != undefined){
      existingInvoices = data;
    } 

    existingInvoices.sort(function(a,b){
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
    setRekeningData([...existingInvoices]);
    let monthlyCostTemp = {};
    existingInvoices.map(function(item, index){
      var invDate = new Date(item.date).toLocaleString();

      const dateNew = new Date(item.date).getMonth();
      const yearNew = new Date(item.date).getFullYear();
      let key = yearNew+"_"+dateNew;
      //const dateArray = invDate.split(".");
      //var dateNew = parseInt(dateArray[1]) -1;
      
      if(key in monthlyCostTemp == false){
        monthlyCostTemp[key] = 0;
      }

      let singelItemPrice = item.total;
      if(typeof singelItemPrice == "string"){
        singelItemPrice = singelItemPrice.replace(",", ".");
      }
      var currentCost = monthlyCostTemp[key];

      var totalPrice = (parseFloat(singelItemPrice) + parseFloat(currentCost)).toFixed(2);
      monthlyCostTemp[key] = totalPrice;
      totalCostTemp = totalCostTemp + parseFloat(singelItemPrice);
    });

    setTotalCost(parseFloat(totalCostTemp.toFixed(2)))

    setMonthlyCost(monthlyCostTemp);
  }

  const loadInvoices = async (pageNumber = 1) =>{
    //const session = await getSession();
  

    let rekeningDataOld = rekeningData;
    if(pageNumber == 1){
      rekeningDataOld = [];
    }

    let searchString = "";

    searchValues.map((singleSearchElement)=>{
      if(singleSearchElement.value != ""){
        searchString += "&"+singleSearchElement.key+"="+singleSearchElement.value;
      }
    })


    setMoreInvoicesAvailable(false);
    try{
      const fetchData = axios.get(`${api_url}/invoices?page=${pageNumber}&pageSize=25${searchString}`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const data = await fetchData;

      if(data.status != 403 && data.status != 500){
        let rekeningDataTempResponse = data.data;
        await rekeningDataTempResponse.map((singleInvoice, i)=>{
          if(singleInvoice.taxPositions == undefined || singleInvoice.taxPositions.length == 0){
            let tempNewTax = {value: singleInvoice.total, taxRate: singleInvoice.mwst};
            rekeningDataTempResponse[i].taxPositions = [];
            rekeningDataTempResponse[i].taxPositions.push(tempNewTax);
          }
        })
        if(rekeningDataTempResponse.length == 25){
          setMoreInvoicesAvailable(true);
        }
        let invoiceDataCombined = rekeningDataTempResponse.concat(rekeningDataOld);
        setRekeningData(invoiceDataCombined);
        calculateMonthlyCost(invoiceDataCombined);
        setRekeningDataOriginal(invoiceDataCombined);
        if(searchForText != "" || searchForStatus != "" || searchForUser != ""){
          searchFor(searchForText, searchForStatus, searchForUser);
        }
        setIsLoading(false);
        return invoiceDataCombined; 
      }else{
        setLoginRequired(false);
        setIsLoading(false);
      }
      
    }catch (ex){
      setLoginRequired(false);
      setIsLoading(false);
    }
  }

  const loadSingleInvoices = async (invoiceId) =>{
    //const session = await getSession();

    try{
      const fetchData = axios.get(`${api_url}/invoices/${invoiceId}`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const data = await fetchData;

      if(data.status != 403 && data.status != 500){
        let rekeningDataTempResponse = data.data;
        //setRekeningData(invoiceDataCombined);
        //calculateMonthlyCost(invoiceDataCombined);
        let rekeningDataTemp = rekeningData;
        let indexToReplace = rekeningData.findIndex(e => e._id == invoiceId);
        rekeningDataTemp[indexToReplace] = rekeningDataTempResponse;
        setRekeningData(rekeningDataTemp)
        
        return rekeningDataTempResponse; 
      }
     
    }catch (ex){
      setLoginRequired(false);
      setIsLoading(false);
    }
  }

  const scrollLoadMoreInvoices = async(pageNumber)=>{
    loadInvoices(pageNumber);
    /*let offset = numberScrolled * 200,
    limit = 200,
    travelExpenseFilterTemp = travelExpenseFilter,
    filterExistsOffset = travelExpenseFilterTemp.findIndex((e)=> e.key == "offset"),
    filterExistsLimit = travelExpenseFilterTemp.findIndex((e)=> e.key == "limit");

    if(filterExistsOffset == -1){
      travelExpenseFilterTemp.push({key: "offset", value: offset});
      travelExpenseFilterTemp.push({key: "limit", value: limit});
    }
    setTravelExpenseLoadHasMore(false);
    setTravelExpenseFilter([...travelExpenseFilterTemp]);
*/
  }

  const loadTravelExpenses = async () =>{

        let loadTravelExpensesUrl = `${api_url}/travelExpense?`;
        /*travelExpenseFilter.map((singleFilter)=>{
          loadTravelExpensesUrl += singleFilter.key +"="+singleFilter.value+"&";
        })*/
        try{
          const fetchData = axios.get(loadTravelExpensesUrl, { 
            headers: { Authorization: `Bearer ${session.accessToken}` }
          });
          const data = await fetchData;
    
          if(data.status != 403 && data.status != 500){
            
            let travelExpensesFromServer = data.data;

            /*if(travelExpenseFilter.findIndex((e)=> e.key = "offset") != -1){
              console.log("COMBINE", travelExpenseData);
              travelExpensesFromServer = travelExpensesFromServer.concat(travelExpenseData);
            }*/

            travelExpensesFromServer.sort(function(a,b){
              return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
            });
            setTravelExpenseData(travelExpensesFromServer);
            /*if(searchForText != "" || searchForStatus != "" || searchForUser != ""){
              searchFor(searchForText, searchForStatus, searchForUser);
            }*/
            setIsLoading(false);
            return data.data; 
          }else{
            setIsLoading(false);
          }
          
        }catch (ex){
          setIsLoading(false);
        }
  }

  const loadInvoiceTypes = async () =>{
    //const session = await getSession();

    try{

      const fetchData = axios.get(`${api_url}/invoiceTypes`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const data = await fetchData;
      

      if(data.status != 403 && data.status != 500){
        setInoviceTypesData(data.data);

        //Next load Invoices
        ;

        return data.data; 
      }
    }catch (ex){
      return
    }
  }





  const deleteSelectedInvoices = async ()=>{
    let rekeningDataTemp = rekeningData;
    selectedInvoicesIds.map((singleInvoice)=>{
      deleteInvoice(singleInvoice._id, true);
      rekeningDataTemp = rekeningDataTemp.filter(e => e._id != singleInvoice._id);
    });
    setIsDeleteOpen(false)
    setSelectedInvoicesIds([]);
    setRekeningData(rekeningDataTemp);
  }

  const deleteInvoice = async (id, bulkDelete) =>{

    const fetchData = await fetch(`${api_url}/invoices/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
      },
    })
    let rekeningDataTemp = rekeningData.filter(e => e._id != id);
    if(!bulkDelete){
      setRekeningData(rekeningDataTemp);
    }
    setEditInvoiceModified(false)
    userNotification("Delete Successfull", "Your invoice has been successfully deleted", "success");
    setChangedInvoices([])
  }

  const updateInvoice = async (data) =>{
    let body = data

    if(data == undefined){
      return;
    }

    const fetchData = await fetch(`${api_url}/invoices/${data._id}`, {
      method: "PATCH",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const fetchDataJson = await fetchData.json();
    if("statusCode" in fetchDataJson){
      userNotification("Update Error", fetchDataJson["message"][0], "error");
      return false;
    }else{
      userNotification("Update Successfull", "Your invoice has been successfully updated", "success");
      setChangedInvoices([fetchDataJson["_id"]]);

      await loadSingleInvoices(data._id)
      return true;
    }
    
  }

  const refundAll = async()=>{
    setIsLoadingRefund(true);
    await rekeningData.map(async (inv)=>{
      let tempInv = inv;
      tempInv.status = "refunded";
      await updateInvoice(tempInv);
    })
    setIsLoadingRefund(false);
    onRefundClose();
  }
  const requestRefundAll = async()=>{
    setIsLoadingRequestRefund(true);
    await rekeningData.map(async (inv)=>{
      let tempInv = inv;
      tempInv.status = "requested";
      await updateInvoice(tempInv);
    })
    setRekeningData([]);
    await loadInvoices();
    setIsLoadingRequestRefund(false);
    onRequestRefundClose();
  }

  const bulkUpdateInvoiceStatus = async()=>{
    setIsLoadingRequestRefund(true);
    let changedInvovicesIds = []
    await Promise.all(
      selectedInvoicesIds.map(async (inv) => {
        let tempInv = inv;
        tempInv.invoiceStatus = selectedInvoiceNextStep._id;
        changedInvovicesIds.push(tempInv._id);
        return updateInvoice(tempInv);
      })
    );

    setRekeningData([]);
    setSelectedInvoicesIds([]);
    setChangedInvoices(changedInvovicesIds);
    loadInvoices();
    setIsLoadingRequestRefund(false);
  }

  const toggleInvoiceSelectMonth = async (year, month)=>{
    console.log(year, month)
    let rekeningDataTemp = rekeningData;
    let forceStatus = true;
    let tempSelectedInvoiceMonths = selectedInvoiceMonths;
    console.log("tempSelectedInvoiceMonths", tempSelectedInvoiceMonths);

    if(tempSelectedInvoiceMonths.includes(year+"-"+month)){
      forceStatus = false;
      console.log("FALSE");
      let tempSelectedInvoiceMonthsNew = tempSelectedInvoiceMonths.filter(item => item !== year+"-"+month);
      setSelectedInvoiceMonths(tempSelectedInvoiceMonthsNew);
    }else{
      tempSelectedInvoiceMonths.push(year+"-"+month);
      setSelectedInvoiceMonths(tempSelectedInvoiceMonths);
    }
    let invoicesToUpdateArray = [];
    // Step 1: Collect Promises
    rekeningDataTemp.map((item, index) => {
      const itemDate = new Date(item.date);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth() + 1; // +1 to make it 1-based (January = 1)

      if (itemYear === year && itemMonth === month) {
          // Assuming toggleInvoiceSelect returns a Promise
          invoicesToUpdateArray.push({_id: item._id, status: forceStatus});
      }

    });
    toggleInvoiceSelect(invoicesToUpdateArray);
  }
  

  const toggleInvoiceSelect = async (invArray) => {


    let rekeningDataTemp = rekeningData,
    selectedInvoicesIdsTemp = selectedInvoicesIds;


    invArray.map((element, index)=>{

      let _id = element._id;
      let forceStatus = element.status;
      let item = rekeningDataTemp.find(el => el._id === _id);

      console.log("Selected:", item);
  
      const yearNew = new Date(item.date).getFullYear();
      const monthNew = new Date(item.date).getMonth() +1;
      let tempSelectedInvoiceMonths = selectedInvoiceMonths;
  
      if(forceStatus != undefined){
        item["selected"] = forceStatus;
        console.log("forceStatus", forceStatus);
        if(forceStatus == true){
          selectedInvoicesIdsTemp.push(item);
        }else{
          selectedInvoicesIdsTemp = selectedInvoicesIdsTemp.filter(e => e._id !== _id);
          console.log("ID:", _id);
          console.log("Filtered:", selectedInvoicesIdsTemp)
        }
      }else if(item["selected"] == undefined || item["selected"] == false){
        item["selected"] = true;
        selectedInvoicesIdsTemp.push(item);
      }else{
        item["selected"] = false;
        selectedInvoicesIdsTemp = selectedInvoicesIdsTemp.filter(e => e._id !== _id);
  
        //Deselect Month
        if(tempSelectedInvoiceMonths.includes(yearNew+"-"+monthNew)){
          forceStatus = false;
          console.log("FALSE");
          let tempSelectedInvoiceMonthsNew = tempSelectedInvoiceMonths.filter(item => item !== yearNew+"-"+monthNew);
          setSelectedInvoiceMonths(tempSelectedInvoiceMonthsNew);
        }
      }

    })




    let invoiceType = "",
    invoiceStatus = "",
    invoiceStatusToSet = undefined,
    countDifferentInoviceTypeStatus = 0;

    selectedInvoicesIdsTemp.map((singleSelectedInvoice) =>{
      if(invoiceType == "" && invoiceStatus == ""){
        invoiceType = singleSelectedInvoice.invoiceType;
        invoiceStatus = singleSelectedInvoice.invoiceStatus;
      }
      if(invoiceType != singleSelectedInvoice.invoiceType || invoiceStatus != singleSelectedInvoice.invoiceStatus){
        countDifferentInoviceTypeStatus += 1;
      }
    });
    if(countDifferentInoviceTypeStatus == 0 && selectedInvoicesIdsTemp.length != 0){
      let invoiceTypeObject = invoiceTypesData.find(e => e._id == invoiceType);

      if(invoiceTypeObject != -1){
        let invoiceStatusObjectIndex = invoiceTypeObject.steps.findIndex(e => e._id == invoiceStatus);
        
        if(invoiceStatusObjectIndex < invoiceTypeObject.steps.length){
          invoiceStatusToSet = invoiceTypeObject.steps[invoiceStatusObjectIndex +1];
        }
      }
      
      setSelectedInvoiceNextStep(invoiceStatusToSet);
    }



    setSelectedInvoicesIds(selectedInvoicesIdsTemp);
    setRekeningData([...rekeningDataTemp]);
    
  
  }

/*
  const toggleInvoiceSelect = async (i, forceStatus = undefined) => {
    let rekeningDataTemp = rekeningData,
    selectedInvoicesIdsTemp = selectedInvoicesIds,
    item = rekeningData[i];
    const yearNew = new Date(item.date).getFullYear();
    const monthNew = new Date(item.date).getMonth() +1;
    let tempSelectedInvoiceMonths = selectedInvoiceMonths;

    if(forceStatus != undefined){
      rekeningDataTemp[i]["selected"] = forceStatus;
      console.log("forceStatus", forceStatus);
      if(forceStatus == true){
        selectedInvoicesIdsTemp.push(rekeningDataTemp[i]);
      }else{
        selectedInvoicesIdsTemp = selectedInvoicesIdsTemp.filter(e => e._id !== rekeningDataTemp[i]._id);
        console.log("Filtered:", selectedInvoicesIdsTemp)
      }
    }else if(rekeningDataTemp[i]["selected"] == undefined || rekeningDataTemp[i]["selected"] == false){
      rekeningDataTemp[i]["selected"] = true;
      selectedInvoicesIdsTemp.push(rekeningDataTemp[i]);
    }else{
      rekeningDataTemp[i]["selected"] = false;
      selectedInvoicesIdsTemp = selectedInvoicesIdsTemp.filter(e => e._id !== rekeningDataTemp[i]._id);

      //Deselect Month
      if(tempSelectedInvoiceMonths.includes(yearNew+"-"+monthNew)){
        forceStatus = false;
        console.log("FALSE");
        let tempSelectedInvoiceMonthsNew = tempSelectedInvoiceMonths.filter(item => item !== yearNew+"-"+monthNew);
        setSelectedInvoiceMonths(tempSelectedInvoiceMonthsNew);
      }
    }


    let invoiceType = "",
    invoiceStatus = "",
    invoiceStatusToSet = undefined,
    countDifferentInoviceTypeStatus = 0;

    selectedInvoicesIdsTemp.map((singleSelectedInvoice) =>{
      if(invoiceType == "" && invoiceStatus == ""){
        invoiceType = singleSelectedInvoice.invoiceType;
        invoiceStatus = singleSelectedInvoice.invoiceStatus;
      }
      if(invoiceType != singleSelectedInvoice.invoiceType || invoiceStatus != singleSelectedInvoice.invoiceStatus){
        countDifferentInoviceTypeStatus += 1;
      }
    });
    if(countDifferentInoviceTypeStatus == 0 && selectedInvoicesIdsTemp.length != 0){
      let invoiceTypeObject = invoiceTypesData.find(e => e._id == invoiceType);

      if(invoiceTypeObject != -1){
        let invoiceStatusObjectIndex = invoiceTypeObject.steps.findIndex(e => e._id == invoiceStatus);
        
        if(invoiceStatusObjectIndex < invoiceTypeObject.steps.length){
          invoiceStatusToSet = invoiceTypeObject.steps[invoiceStatusObjectIndex +1];
        }
      }
      
      setSelectedInvoiceNextStep(invoiceStatusToSet);
    }




    setSelectedInvoicesIds(selectedInvoicesIdsTemp);
    setRekeningData([...rekeningDataTemp]);
  }
*/
  const previewForwards = async () =>{
    
    let invoiceIdTemp = selectedInvoice,
    invoicesLimit = rekeningData.length;

    if(invoiceIdTemp < invoicesLimit){
      openInvoice(invoiceIdTemp+1);
    }
  }


  const previewBackwards = async () =>{
    
    let invoiceIdTemp = selectedInvoice,
    invoicesLimit = rekeningData.length;

    if(invoiceIdTemp > 0){
      openInvoice(invoiceIdTemp-1);
    }
  }

  const openInvoice = async (id) =>{
    setEditInvoiceModified(false)

    /*router.replace({
      query: { ...router.query, i: rekeningData[id]["_id"]},
    });*/

    let currentDate = new Date().toUTCString();
    if(id == "invoice"){
      setCreateInvoice(true);
      setCreateInvoiceType("invoice");
      setEditInvoiceReadonlyReason("");
      setEditInvoiceReadonly(false);
      /*
      let invData = emptyInvoice;
      invData.category = categoriesData[0]._id;
      saveInvoiceData(invData);
      */

      loadBudgetSpending(categoriesData[0]._id)
    }else if(id == "travel-expense"){
      console.log("Travel Expense");
      setCreateInvoice(true);
      setCreateInvoiceType("travel-expense");
      setEditInvoiceReadonlyReason("");
      setEditInvoiceReadonly(false);
      /*
      let invData = emptyInvoice;
      invData.category = categoriesData[0]._id;
      saveInvoiceData(invData);
      */

      loadBudgetSpending(categoriesData[0]._id)
    }else{

      let tempEditInvoice = rekeningData[id];
      let editInvoiceReadonlyTemp = false;
      let editInvoiceReadonlyReasonTemp = "";
      
      //User ist owner 
      if((session.user_data["_id"] != tempEditInvoice.author && !session.rights.includes("view-all-invoices"))){
        editInvoiceReadonlyTemp = true;
        editInvoiceReadonlyReasonTemp = "This invoice is locked since it is currently in editing by its owner";
        console.log("READ ONLY");
      //Editor oder Admin kann freigeben
      }


      setEditInvoiceReadonlyReason(editInvoiceReadonlyReasonTemp);
      setEditInvoiceReadonly(editInvoiceReadonlyTemp);

      setCreateInvoice(false);
      setSelectedInvoice(id);
      currentDate = rekeningData[id].date;

      loadBudgetSpending(rekeningData[id].category);
    }

    invoiceOnOpen(); 

  }


  if(session == null ){
    signIn()
    //window.location.replace(process.env.NEXT_PUBLIC_NEXTAUTH_URL+"/auth/signin");
    return(
      <>
    </>
    )
  }



  const searchForValues = async (key, value) =>{
    let searchValuesTemp = searchValues,
    searchTextIndex = searchValuesTemp.findIndex(e => e.key == key);

    if(searchTextIndex == -1){
      searchValuesTemp.push({key: key, value: value});
    }else{
      searchValuesTemp[searchTextIndex] = {key: key, value: value};
    }
    setSearchValues(searchValuesTemp);
    loadInvoices()
  }



  const searchFor = async (text, status, user) =>{
    let tempTotalValue = 0;
    let newRekeningData = [],
    searchValue = text;
    rekeningDataOriginal.map((item)=>{
      let add = false;

      if(item.shop.toLowerCase().includes(searchValue) || item.total.includes(searchValue)){
        add = true;
      }

      if(add == true && status != ""){
        if(item.status == status){
          add = true;
        }else{
          add = false;
        }
      }
      
      if(add == true && user != ""){
        if(item.author == user){
          add = true;
        }else{
          add = false;
        }
      }

      if(add == true){
        newRekeningData.push(item);
        tempTotalValue = tempTotalValue + parseFloat(item.total);
      }
    })
    setTotalVisibleValue(""+tempTotalValue.toFixed(2));

    if(text == "" && status == "" && user == ""){
      newRekeningData = rekeningDataOriginal
    }
    calculateMonthlyCost(newRekeningData)
    setRekeningData(newRekeningData);
  }

  const downloadSelected = async () => {
    exportExcel(selectedInvoicesIds);
  }

  const exportExcel = async(dataToExport)=>{
    const data = []; 
    onDownloadOpen();
    let tempDateXls = new Date();
    let fileName = tempDateXls.getFullYear()+"-"+tempDateXls.getMonth()+"-"+tempDateXls.getDay()+"-rekening-export";
    const exportType = 'csv'  
    let imageArray = [];
    dataToExport.map((item)=>{
      let newItem = {};
      newItem["Shop"] = item.shop;
      newItem["Total"] = item.total.replace(".", ",");
      newItem["TotalNet"] = item.totalNet.replace(".", ",");
      newItem["Tax"] = item.tax.replace(".", ",");
      newItem["TaxRate"] = item.mwst;
      if(item.currency != undefined){
        newItem["Currency"] = item.currency;
      }else{
        newItem["Currency"] = "EUR";
      }
      newItem["Date"] = new Date(item.date).toLocaleDateString('de-DE');
      newItem["Status"] = item.status;
      if(item.authorData.length != 0){
        newItem["Person"] = item.authorData[0].username;
      }else{
        newItem["Person"] = "Removed User";
      }
      if (item.scanAttached != ""){
        let imageObject = {}; 
        imageObject["url"] = item.scanAttached;
        let tempDate = new Date(item.date);
        let tempDateString = tempDate.getFullYear()+"-"+(tempDate.getMonth()+1)+"-"+tempDate.getDay();
        imageObject["filename"] = tempDateString+"-"+item.shop.replace(" ", "_").toLowerCase()+"-"+item.total.replace(".",",")+"-"+item.authorData[0].username.replace(" ", "_")+"-rekening"
        imageArray.push(imageObject);

        newItem["Filename"] = imageObject["filename"];
      }
      data.push(newItem);
    });
    await handleDownload(imageArray);
    exportFromJSON({ data, fileName , exportType })  
  }

  const handleDownload = (images) => {
    const zip = new JSZip();
    const imgFolder = zip.folder("images");
    const promises = [];
    let ext = "";
    images.map((image, i) =>
      promises.push(
        fetch(image["url"], {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${session.accessToken}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            let extArray = image["url"].split(".");
            ext = extArray.pop();
            let data = response.blob();
            return imgFolder.file(`${image["filename"]}.${ext}`, data);
          })
      )
    );
    Promise.all(promises).then(() =>
      zip.generateAsync({ type: "blob" }).then(function(content) {
        // see FileSaver.js

        let tempDateXls = new Date();
        let tempDateStringXls = tempDateXls.getFullYear()+"-"+(tempDateXls.getMonth()+1)+"-"+tempDateXls.getDay()+"-rekening-export"
        saveAs(content, tempDateStringXls+".zip");
        onDownloadClose();
        userNotification("Download Successfull","Invoices successfully downloaded","success");
      })
    );
  };


  //BULK UPLOAD CHANGE SETTINGS
  const onChangeSettingsBulkUpload = (key, val) => {
    
    let bulkUploadSettingsTmp = bulkUploadSettings;
   
    bulkUploadSettingsTmp[key] = val;
    //SAVE
    setBulkUploadSettings({...bulkUploadSettingsTmp});
  }

  const createNewInvoice = async (data, bulkUpload = false) =>{
    let body = data;


    if(data == undefined){
      return;
    }
    
    delete body.authorData;


    const fetchData = await fetch(`${api_url}/invoices`, {
      credentials: "include",
      method: "POST",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    const fetchDataJson = await fetchData.json();

    if("statusCode" in fetchDataJson){
      userNotification("Creation Error", fetchDataJson["message"][0], "error");
   
    }else{
      userNotification("New Invoice Created","Your new invoice has been successfully created","success");
    

      await setRekeningData([]);
      await loadInvoices()
      if(!bulkUpload  && "statusCode" in fetchDataJson == false){
        setChangedInvoices([fetchDataJson._id]);
        setTimeout(()=>{
          scroller.scrollTo(fetchDataJson._id, {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: -100, // Scrolls to element + 50 pixels down the page
          })
        },200)
      }
    }
    return fetchDataJson;
  }



  //BULK UPLOAD 
  const bulkUploadPrep = (ev) =>{
    ev.preventDefault();
    onOpenBulkUploadPrep()
    setBulkUploadSettings({invoiceType: "", category: ""});
    
    
    setBulkUploadCounterTotal(Object.keys(ev.dataTransfer.items).length);
    
    let fileList = [];
    let errorFiles = 0;
    // Use DataTransferItemList interface to access the file(s)
    [...ev.dataTransfer.items].forEach((item, i) => {
      // If dropped items aren't files, reject them
      if (item.kind === 'file') {
        const file = item.getAsFile();
        fileList.push(file);
      }else{
        errorFiles = errorFiles+1;
      }
    });

    setBulkUploadData(fileList);
  }

  const bulkUpload = () => {
    onCloseBulkUploadPrep();
    
    setBulkInsertData(bulkUploadData);
    onOpenBulkUpload();
  }




  const uploadDragOver = (ev) =>{
    ev.preventDefault();
  }

  const openTravelExpense = async()=>{
    setTravelExpense(emptyTravelExpense)
    setNewTravelExpense(true);
    onTravelExpenseOpen();
  }

  const createTravelExpenseFromInvoices = async()=>{
    let newTravelExpense = emptyTravelExpense;
    newTravelExpense.childInvoices = selectedInvoicesIds;
    setTravelExpense(newTravelExpense);
    setNewTravelExpense(true);
    onTravelExpenseOpen();
  }


  if(rekeningData == null){
    return(
      <div></div>
    )
  }

  let lastDate = undefined;


  const components = {
    Card: CustomCard,
    NewCardForm: NewCardForm,
  };

  let rekeningDataBoard = {
    lanes: [
      {
        id: 'lane1',
        title: 'Planned Tasks',
        label: '2/2',
        cards: rekeningData
      },
      {
        id: 'lane2',
        title: 'Completed',
        label: '0/0',
        cards: []
      }
    ]
  } 




  return(
    <Container onDrop={(ev)=>bulkUploadPrep(ev)} onDragOver={(ev)=>uploadDragOver(ev)}>
    <Head>
      <title>Rekening Expense Dashboard</title>
      <meta property="og:title" content="Rekening Dashboard" key="title" />
    </Head>
    <div className='content-wrapper' >
      <MenuElement onUpgradeOpen={onUpgradeOpen} openInvoice={openInvoice} openTravelExpense={openTravelExpense} sessionData={session} ></MenuElement>
      <TravelExpensesManager invoiceTypesData={invoiceTypesData} loadTravelExpenses={loadTravelExpenses} userData={userData} rekeningData={rekeningData} newTravelExpense={newTravelExpense} userNotification={userNotification} setTravelExpense={setTravelExpense} travelExpense={travelExpense} isTravelExpenseOpen={isTravelExpenseOpen} onTravelExpenseClose={onTravelExpenseClose}  categoriesData={categoriesData} session={session}></TravelExpensesManager>
      <SubscriptionManager isUpgradeOpen={isUpgradeOpen} onUpgradeClose={onUpgradeClose} onUpgradeOpen={onUpgradeOpen} forceDisplay={false} session={session}></SubscriptionManager>
      {tabIndex == 0 &&
      <Box>
      <SimpleGrid mb={"2"} columns={16} spacing={5}>
        <GridItem colSpan={7}>
          <Heading  as='h2' size='lg' ml="0%" mb="2" style={{width: "100%"}}>
            All Invoices
          </Heading>
        </GridItem>
        <GridItem colSpan={8}>
          <Heading  as='h2' size='lg' ml="0%" mb="2" style={{textAlign: "right", width: "100%"}}>
            {totalCost}€
          </Heading>
        </GridItem>
      </SimpleGrid>
      <SimpleGrid mb={"2"}columns={16} spacing={5}>
        <GridItem colSpan={12}>
          <Heading  as='h4' size='sm' mb="1">Search</Heading>
          <Input type={"search"}  onKeyUp={(e)=>{searchForValues("searchText", e.currentTarget.value)}} placeholder="Search..."></Input>
        </GridItem>
        <GridItem colSpan={4}>
          <Menu matchWidth={true}>
            <MenuButton mt="6" isFullWidth={true} as={Button} rightIcon={<IoChevronDown />}>
              Quick Actions
            </MenuButton>
            <MenuList>
              <Text borderBottom={"1px solid #E2E8F0"} pl="3" pb="2" pt="2" fontWeight={"bold"} >Download:</Text>
              <MenuItem minH='45px' onClick={()=>exportExcel(rekeningData)}>
                <IoDownload size={15}/>
                <Text  as='h4' ml="2" mr="2" size='sm'>
                  Download All Visible
                </Text>
              </MenuItem>
              <MenuItem isDisabled={selectedInvoicesIds.length == 0} minH='45px' borderTop={"1px solid #E2E8F0"} onClick={()=>downloadSelected()}>
                <IoCheckbox size={15} />
                <Text  as='h4' ml="2" size='sm'>
                  Download Selected ({selectedInvoicesIds.length})
                </Text>
              </MenuItem>
              {selectedInvoicesIds.length != 0 &&
              <Text borderTop={"1px solid #E2E8F0"} pl="3" pb="2" pt="2" fontWeight={"bold"}>
                Travel Expense:
              </Text>
              }

              {selectedInvoicesIds.length != 0 && selectedInvoicesIds.length === 0 &&
              <MenuItem onClick={()=>createTravelExpenseFromInvoices()} minH='45px' borderTop={"1px solid #E2E8F0"}>
                <IoGlobe size={15}/>
                <Text  as='h4' ml="2" size='sm'>
                  Create Travel Expenese for Selected
                </Text>
              </MenuItem>
              }
              <Text borderTop={"1px solid #E2E8F0"} pl="3" pb="2" pt="2" fontWeight={"bold"}>
                Update:
              </Text>
              {selectedInvoiceNextStep != undefined &&
              <MenuItem isDisabled={selectedInvoicesIds.length == 0} onClick={()=>bulkUpdateInvoiceStatus()} minH='45px' borderTop={"1px solid #E2E8F0"}>
                <IoCheckmarkDoneCircleSharp size={15}/>
                <Text  as='h4' ml="2" size='sm'>
                  {selectedInvoiceNextStep.title} ({selectedInvoicesIds.length})
                </Text>
              </MenuItem>
              }

              <MenuItem isDisabled={selectedInvoicesIds.length == 0} onClick={()=>setIsDeleteOpen(true)} minH='45px' borderTop={"1px solid #E2E8F0"}>
                <IoTrash size={15}/>
                <Text  as='h4' ml="2" size='sm'>
                  Delete Selected ({selectedInvoicesIds.length})
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </GridItem>

            
        <GridItem colSpan={4}>
          <Heading  as='h4' size='sm' mb="1">Invoice Type</Heading>
          <Select onChange={(e)=>{searchForValues("searchInvoiceType", e.currentTarget.value)}} placeholder="All">
            {invoiceTypesData.map((singleInvoiceType)=>{
              return(
                <option key={singleInvoiceType._id+"list-inv-types"} value={singleInvoiceType._id}>{singleInvoiceType.title}</option>
              )
            })}
          </Select>
        </GridItem>
        {/*<GridItem colSpan={3}>
          <Heading  as='h4' size='sm' mb="1">Status</Heading>
          <Select onChange={(e)=>{searchForStatusInput(e)}}>
            <option value="">All</option>
            <option value="private">In Progress</option>
            <option value="requested">Waiting for Refund</option>
            <option value="refunded">Refund Done</option>
          </Select>
          </GridItem>*/}
        <GridItem colSpan={4}>
          <Heading  as='h4' size='sm' mb="1">Status</Heading>
          <Select  onChange={(e)=>{searchForValues("searchInvoiceStatus", e.currentTarget.value)}} placeholder="All">
            {invoiceTypesData.map((singleType)=>{
              return(
                <optgroup key={singleType._id+"filter"} label={singleType.title}>
                  {singleType.steps.map((sindgelStep)=>{
                    return(
                      <option key={sindgelStep._id+"filter"} value={sindgelStep._id}>{sindgelStep.title}</option>
                    )
                    
                  })}
                </optgroup>
              )
            })}
          </Select>
        </GridItem>
        <GridItem colSpan={4}>
          <Heading  as='h4' size='sm' mb="1">Budget</Heading>
          <Select onChange={(e)=>{searchForValues("searchCategory", e.currentTarget.value)}} placeholder="All">
            {categoriesData.map((singelCategory)=>{
              return(
                <option key={singelCategory._id+"filter-budg"} value={singelCategory._id}>{singelCategory.title}</option>
              )
            })}
          </Select>
        </GridItem>
        <GridItem colSpan={4}>
          <Heading  as='h4' size='sm' mb="1">User</Heading>
          <Select  onChange={(e)=>{searchForValues("searchUser", e.currentTarget.value)}} placeholder="All">
            {userData.map((singleUser)=>{
              return(
                <option key={singleUser._id+"filter-user-inv"} value={singleUser._id}>{singleUser.username}</option>
              )
            })}
          </Select>
        </GridItem>
        
      </SimpleGrid>
      <Divider mt="5"></Divider>
      {rekeningData.length != 0 && isLoading == false &&
        <div className='create-first-invoice'>
          <Center>
            <Heading as="h2" size={"md"} mt="10" mb="10">
              <IoCloudUpload style={{float: 'left', marginRight: "10", marginTop: "2"}}></IoCloudUpload>
              Drag & Drop Your Invoice Here
              <IoCloudUpload style={{float: 'right', marginLeft: "10", marginTop: "2"}}></IoCloudUpload>
            </Heading>
          </Center>
        </div>
      }
      {isLoading &&
        <Center>
          <Spinner
          thickness='5px'
          speed='1s'
          emptyColor='gray.200'
          color='orange.500'
          size='xl'
          mt="10"
          />
        </Center>
      }

    {rekeningData.length == 0 && isLoading == false &&
        <div className='create-first-invoice'>
          <Center>
            <Heading as="h2" size={"md"} mt="10">
              You Need Some Help To Learn How To Use rekening?
            </Heading>
          </Center>
          <Center>
            <Button colorScheme='rekening.main' mt="10" mb="10" onClick={onTutorialOpen}>
              <IoHelpCircle color="black" />
              <Text color="black" ml="1">Open User Guide</Text>
            </Button>
          </Center>
        </div>
      }
      {rekeningData.length == 0 && isLoading == false &&
        <div className='create-first-invoice'>
          <Center>
            <Heading as="h2" size={"md"} mt="10">
              Drag & Drop Your First Invoice
            </Heading>
          </Center>
          <Center>
            <Heading as="h2" size={"md"} mt="10">
              OR
            </Heading>
          </Center>
          <Center>
            <Button colorScheme='rekening.main' mt="10" mb="10" onClick={()=>{openInvoice("invoice")}}>
              <IoAddCircle color="black" />
              <Text color="black" ml="1">Add Your First Invoice</Text>
            </Button>
          </Center>
        </div>
      }
      {rekeningData.length == 0 && isLoading == false &&
        <div className='create-first-invoice' style={{marginBottom: "120px"}}>
          <Center>
            <Heading as="h2" size={"md"} mt="10">
              Invite Your Team To Collaborate On Invoices
            </Heading>
          </Center>
          <Center>
            <Button colorScheme='rekening.main' mt="10" mb="10" onClick={()=>{}}>
              <IoPersonAdd color="black" />
              <Text color="black" ml="1">Add User To Your Team</Text>
            </Button>
          </Center>
        </div>
      }
      {/* KANBAN VIEW IMPLEMENT LATER rekeningData.length != 0 &&
        <Board  components={components} 
        laneStyle={{
          border: "1px solid #E2E8F0",
          borderRadius: "6px",
          backgroundColor: "white"
        }}
        style={{
          backgroundColor: '#fff'
        }}  data={rekeningDataBoard} editable />
      */}

      <InfiniteScroll
        datalength={rekeningData.length}
        pageStart={0}
        loadMore={scrollLoadMoreInvoices}
        hasMore={moreInvoicesAvailable}
        loader={<Center>
          <Spinner
          thickness='5px'
          speed='1s'
          emptyColor='gray.200'
          color='orange.500'
          size='xl'
          mt="10"
          />
        </Center>}
      >
      <SimpleGrid key="invoices" style={{paddingBottom: "50px"}} columns={1} spacing={3}>
        {rekeningData.map((item, index) => {
                
                const date= new Date(item.date).toLocaleDateString('de-DE');

                const dateArray = ["January", "February", "March", "April", "Mai", "June", "July", "August", "September", "October", "November", "December"];

                var invDate = new Date(item.date).toLocaleString();
                //const dateArray = new Date(item.date).getMonth();
                const dateNew = new Date(item.date).getMonth();
                const yearNew = new Date(item.date).getFullYear();
                const monthNew = new Date(item.date).getMonth() +1;
                let addDateHeader = undefined,
                addDateHeaderYear = "",
                addDateHeaderCost = undefined;
                
                let invoiceCurrency = item.currency;
                if(invoiceCurrency == "EUR" || invoiceCurrency == "" || invoiceCurrency == undefined){
                  invoiceCurrency = "€"
                }else if(invoiceCurrency == "USD"){
                  invoiceCurrency = "$"
                }else if(invoiceCurrency == "JPY"){
                  invoiceCurrency = "¥"
                }else if(invoiceCurrency == "GBP"){
                  invoiceCurrency = "£"
                }else if(invoiceCurrency == "INR"){
                  invoiceCurrency = "₹"
                }else if(invoiceCurrency == "CAD"){
                  invoiceCurrency = "$"
                }

                if(dateNew != lastDate){
                    lastDate = dateNew;
                    addDateHeader = dateNew;
                    addDateHeaderYear = yearNew.toString();
                    addDateHeaderCost = monthlyCost[yearNew+"_"+dateNew];
                }
                if(index == rekeningData.length -1){
                    lastDate = undefined
                }

                //Mark new inovices

                let iconData = categoriesData.find((e)=> e._id == item.category)

                let IconWeb = IoBriefcase;
                if(iconData != undefined){
                  IconWeb = iconData.iconWebElement;
                }

                let invoceSelectClassList = "rekening-invoice-item";
                if(item.selected != undefined && item.selected == true){
                  invoceSelectClassList = "rekening-invoice-item selected"
                }

                if(changedInvoices.includes(item._id)){
                  invoceSelectClassList += " changed-invoice"
                }

                let invoiceStatus = "";
                let invoiceStatusColor = "cream";
                
                try {
                  let invoiceType = invoiceTypesData.find(e=> e._id == item.invoiceType);

                  if(invoiceType != undefined){
                    let invoiceStatusStep = invoiceType.steps.find(e=> e._id == item.invoiceStatus);
                    if(invoiceStatusStep != undefined){
                      invoiceStatus = invoiceStatusStep.title;
                      invoiceStatusColor = invoiceStatusStep.color;
                    }
                  }
                } catch (error) {
                  
                }

                let monthSelected = "";
                if(selectedInvoiceMonths.includes(yearNew+"-"+monthNew)){
                  monthSelected = "selected"
                }

                return(
                  <div key={item._id+"list"}>
                  {addDateHeader != undefined &&

                <SimpleGrid  mb={"2"} columns={16} spacing={5}>
                    <GridItem colSpan={8}>
                      <HStack  mt="5"  spacing='7'>
                        <Box onClick={(ev)=>{toggleInvoiceSelectMonth(yearNew, monthNew)}} className={"list-checkbox "+monthSelected}>
                            <Box className='list-checkbox-inner'/>
                        </Box>
                        <Heading  as='h3' size='md' ml="0%" style={{width: "100%"}}>
                        {addDateHeaderYear} {dateArray[addDateHeader]}
                        </Heading>

                      </HStack>
                    </GridItem>
                    <GridItem colSpan={7}>
                      <Heading  as='h3' size='md' textAlign="right" ml="0%" mt="5" style={{width: "100%"}}>
                        {addDateHeaderCost}{currency}
                      </Heading>
                    </GridItem>
                  </SimpleGrid>
                  }
                  <Box className={invoceSelectClassList} padding="3" borderWidth='1px' borderRadius='lg' style={{transition: "all 0.2s"}}
                    _hover={{
                      cursor: "pointer",
                      backgroundColor: "#f4f4f4"
                    }}
                    onClick={()=>{openInvoice(index)}}
                  >
                    <Element name={item._id}></Element>
                    <Grid templateColumns='repeat(16, 1fr)' gap={4}>
                      <GridItem colSpan={1}>
                        <HStack spacing='5px'>
                        <Box onClick={(ev)=>{
                            ev.stopPropagation();
                            ev.preventDefault();
                            toggleInvoiceSelect([{_id:item._id}])}}
                            className={"list-checkbox"}>
                              <Box className='list-checkbox-inner'/>
                        </Box>
                        {item.authorData.length != 0 && item.authorData[0].profilePicture == "" &&
                          <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={item["authorData"][0]["username"]} />
                        }
                        {item.authorData.length != 0 && item.authorData[0].profilePicture != "" &&
                          <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' src={item["authorData"][0]["profilePicture"]} />
                        }
                        {item.authorData.length == 0 &&
                          <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={""} />
                        }
                        <div className='rekening-invoice-icon'>
                          <IconWeb style={{marginLeft: "2px", marginTop: "2px"}} fontSize={"30px"}></IconWeb>
                        </div>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={5}>
                        <Heading  as='h4' size='sm'>
                          {item.shop}
                        </Heading>
                        <Box display='flex' alignItems='baseline'>
                            {item.invoiceKind != undefined && item.invoiceKind == "travel-expense" &&
                            <IoEarth fontSize="13" style={{marginRight: 3}} color="#718096"></IoEarth>
                            }
                            <Box
                              color='gray.500'
                              fontWeight='semibold'
                              letterSpacing='wide'
                              textTransform='uppercase'
                              ml='0'
                            >
                              {date}
                            </Box>
                          </Box>
                      </GridItem>
                      <GridItem colSpan={4}>
                        {item.scanAttached == "" && item.invoiceKind != "travel-expense" &&
                          <Heading flex="" as='h4' size='sm' color="#be3737">
                            <IoAlertCircle style={{float: "left", fontSize: 13, marginTop:"2px", marginRight: "3px"}}></IoAlertCircle>
                            Missing receipt
                          </Heading>
                        } 
                        {item.scanAttached != "" && item.invoiceKind != "travel-expense" &&
                          <Heading  as='h4' size='sm'>
                            <IoReceipt style={{float: "left", fontSize: 13, marginTop:"2px", marginRight: "3px"}}></IoReceipt>
                            Receipt attached
                          </Heading>
                        } 
                        {item.invoiceKind == "travel-expense" &&
                          <Heading  as='h4' size='sm'>
                            <IoCar style={{float: "left", fontSize: 14, marginTop:"2px", marginRight: "3px"}}></IoCar>
                            Travel expense
                          </Heading>
                        } 
                        {invoiceStatus != "" &&
                        <Box display='flex' alignItems='baseline'>
                          <Badge borderRadius='full' px='2' mt="1" pr="28px" backgroundColor={invoiceStatusColor}>
                            <IoLockClosed style={{float: "left", marginTop:"2px", marginRight: "3px"}}/>
                            <Text>{invoiceStatus}</Text>
                          </Badge>
                        </Box>
                        }
                      </GridItem>
                      <GridItem colSpan={5} textAlign="right">
                        <Heading  as='h4' size='sm'>
                          {item.total}{invoiceCurrency}
                        </Heading>
                        <Box display='flex' alignItems='baseline'>
                            {/*<Badge borderRadius='full' px='2' colorScheme='teal'>
                              Schritt {index}
                            </Badge>*/}
                            <Box
                              textAlign="right"
                              color='gray.500'
                              fontWeight='semibold'
                              letterSpacing='wide'
                              textTransform='uppercase'
                              ml='0'
                              width="100%"
                            >
                              {item.totalNet}{invoiceCurrency}
                            </Box>
                          </Box>
                      </GridItem>
                      <GridItem>
                        <div className='rekening-item-right'>
                          <IoChevronForward fontSize={"35px"}></IoChevronForward>
                        </div>
                      </GridItem>
                    </Grid>
                    </Box>

                  </div>
              )})}
            </SimpleGrid>
            </InfiniteScroll>
          </Box>
          }
          {tabIndex == 1 &&
            <TravelExpenseListView setTravelExpenseLoadHasMore={setTravelExpenseLoadHasMore} travelExpenseLoadHasMore={travelExpenseLoadHasMore} travelExpenseFilter={travelExpenseFilter} loadTravelExpenses={loadTravelExpenses} setTravelExpenseFilter={setTravelExpenseFilter} userData={userData} travelExpenseData={travelExpenseData} setTravelExpenseData={setTravelExpenseData} setNewTravelExpense={setNewTravelExpense} onTravelExpenseOpen={onTravelExpenseOpen} setTravelExpense={setTravelExpense} travelExpense={travelExpense} currency={currency} query={query} session={session} categoriesData={categoriesData}></TravelExpenseListView>
          }
      </div>

      {/*<div className="bottom-actions">
        <Flex width="95%" mt="2">
          <Spacer></Spacer>
          <Button size="md" onClick={()=>exportExcel()}>
            <IoDownload size={15}/>
            <Text  as='h4' ml="2" mr="2" size='sm'>
                Download All
            </Text>
          </Button>
        </Flex>
      </div>*/}
      <InvoiceDetailDrawer
        createInvoiceType={createInvoiceType}
        setBulkUploadCounterFinished={setBulkUploadCounterFinished}
        bulkUploadSettings={bulkUploadSettings}
        setBulkInsertData={setBulkInsertData}
        bulkInsertData={bulkInsertData}
        uploadInProgress={uploadInProgress}
        categoriesData={categoriesData}
        userData={userData} 
        setUploadInProgress={setUploadInProgress}
        editInvoiceReadonly={editInvoiceReadonly}
        previewBackwards={previewBackwards}
        previewForwards={previewForwards}
        editInvoiceReadonlyReason={editInvoiceReadonlyReason}
        loadInvoices={loadInvoices}
        invoiceTypesData={invoiceTypesData}
        loadBudgetSpending={loadBudgetSpending}
        loadSingleInvoices={loadSingleInvoices}
        scroller={scroller}
        rekeningData={rekeningData}
        setRekeningData={setRekeningData}
        invoiceIsOpen={invoiceIsOpen}
        session={session}
        setChangedInvoices={setChangedInvoices}
        invoiceOnClose={invoiceOnClose}
        setSelectedInvoice={setSelectedInvoice}
        selectedInvoice={selectedInvoice}
        userNotification={userNotification}
        createInvoice={createInvoice}
        setCreateInvoice={setCreateInvoice}
        setDuplicateCurrentFile={setDuplicateCurrentFile}
        setDuplicateUserDecision={setDuplicateUserDecision}
        setIsDuplicateModalOpen={setIsDuplicateModalOpen}
        />
      



      <Modal isOpen={isRefundOpen} onClose={onRefundClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Refund all invoices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure to set all <b>{rekeningData.length} pending invoices</b> with total value of <b>{totalVisibleValue}€</b> to refunded?</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onRefundClose} mr={3}>Close</Button>
            <Button isLoading={isLoadingRefund} onClick={()=>{refundAll()}} mr={3}>
              <IoCheckmarkDoneCircleSharp style={{fontSize: "1.2em", marginRight:"5px"}}></IoCheckmarkDoneCircleSharp>
              Refund Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isRequestRefundOpen} onClose={onRequestRefundClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Refund all Invoices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure to set request refund for all <b>{rekeningData.length} invoices in progess</b> with total value of <b>{totalVisibleValue}€</b>?</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onRequestRefundClose} mr={3}>Close</Button>
            <Button isLoading={isLoadingRequestRefund} onClick={()=>{requestRefundAll()}} mr={3}>
              <IoCheckmarkDoneCircleSharp style={{fontSize: "1.2em", marginRight:"5px"}}></IoCheckmarkDoneCircleSharp>
              Request Refund of all Invoices 
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDownloadOpen} onClose={onDownloadClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download all visible invoices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Text>Prepearing Download</Text>
            </Center>
            <Center mt="5">
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='orange'
                size='md'
              />
            </Center>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* BULK UPLOAD PREPERATION*/}
      <Modal closeOnOverlayClick={false} isOpen={isOpenBulkUploadPrep} onClose={onCloseBulkUploadPrep}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bulk Import Invoices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mt="2">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Invoice Type</Text>
              <Select
                value={bulkUploadSettings.invoiceType}
                onChange={(e) =>{ onChangeSettingsBulkUpload(`invoiceType`, e.target.value)}}
              >
                <option value="" disabled>Please select the invoice type</option>
                {invoiceTypesData.map((singleCategory)=>{
                  return(
                    <option key={singleCategory._id+"-bulk-categories"} value={singleCategory._id}>{singleCategory.title}</option>
                  )
                })}
              </Select>
            </Box>

            <Box mt="4">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Budget</Text>
              <Select
                value={bulkUploadSettings.category}
                onChange={(e) =>{ onChangeSettingsBulkUpload(`category`, e.target.value)}}
              >
                <option value="" disabled selected>Please select the category</option>
                {categoriesData.map((singleCategory)=>{
                  return(
                    <option key={singleCategory._id+"budget"} value={singleCategory._id}>{singleCategory.title}</option>
                  )
                })}
              </Select>
            </Box>
            
          </ModalBody>

          <ModalFooter>
            <Button onClick={()=>{}} mr={3}>
              Cancel
            </Button>
              
            <Button 
              variant='outline'
              isLoading={isLoadingRefund}
              onClick={()=>{bulkUpload()}}
              disabled={bulkUploadSettings.category == "" || bulkUploadSettings.invoiceType ==""}
            >
              <IoColorWand style={{fontSize: "1.2em", marginRight:"5px"}}></IoColorWand>
              Start Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* BULK UPLOAD LOADER*/}
      <Modal isOpen={isOpenBulkUpload} onClose={onCloseBulkUpload}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bulk Importing Invoices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            
            <Center>
              {bulkUploadCounterFinished != bulkUploadCounterTotal &&
              <Spinner
                thickness='4px'
                speed={isDuplicateModalOpen? "10000s" : "0.65s"}
                emptyColor='gray.200'
                color='orange'
                size='md'
              />
              }
            </Center>
            {!isDuplicateModalOpen &&
            <Center>
              <Text mt="5">Finished importing <b>{bulkUploadCounterFinished}</b> out of <b>{bulkUploadCounterTotal}</b> files</Text>
            </Center>
            }
            <Progress mt="5" value={(bulkUploadCounterFinished/bulkUploadCounterTotal)*100} size='xs' colorScheme='orange' />

            {isDuplicateModalOpen &&
            <div>
              <Center mt="5">
                <Text><b>Duplicated Document</b></Text>
              </Center>
              <Center mt="1">
                <Text>"{duplicateCurrentFile.name}"</Text>
              </Center>
              </div>
            }
          </ModalBody>

          <ModalFooter>
            {bulkUploadCounterFinished == bulkUploadCounterTotal &&
              <Button mr={3} onClick={onCloseBulkUpload}>
                Done
              </Button>
            }
            {bulkUploadCounterFinished != bulkUploadCounterTotal && !isDuplicateModalOpen &&
              <Button mr={3} isLoading={true} disabled={true} onClick={onCloseBulkUpload}>
                Done
              </Button>
            }
            {isDuplicateModalOpen &&
              <Button onClick={handleDuplicateOverwrite}  mr="3">
                Import duplicate 
              </Button>
            }
            {isDuplicateModalOpen &&
              <Button onClick={handleDuplicateSkip} colorScheme="rekening.main">
                Skip This Document
              </Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DeleteModal 
              isOpen={isDeleteOpen}
              onCancel={()=>setIsDeleteOpen(false)}
              onConfirm={deleteSelectedInvoices}
              headerMessage={"Delete "+selectedInvoicesIds.length+" Invoices"}
              bodyMessage={"Are you sure you want to delete all "+selectedInvoicesIds.length+" selected invoice? This can't be undone!"}
            ></DeleteModal>
      <TutorialModal isOpen={isTutorialOpen} onClose={onTutorialClose}/>
    </Container>
  )
}
export async function getServerSideProps({req, query}) {
  let headers = {}
  const tabIndexMenu = query.tabIndexMenu || 0;
  const sessionServerSide = await getSession({ req });
  let checkSubscription =  query.checkSubscription || null;
  return { props: { sessionServerSide, checkSubscription, tabIndexMenu} }
  // Use this session information where you want.
}
export default Index