import {
    Link as ChakraLink,
    Text,
    Box,
    Select,
    Divider,
    Heading,
    Grid,
    GridItem,
    Input,
    cookieStorageManager,
    Checkbox,
    Tooltip,

} from '@chakra-ui/react'

import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import React, {useState} from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';



const TravelExpensesElement = ({changeText ,countryListAllowance, travelExpense, readOnlyAdminFields, editInvoiceErrorArray,updateMeals,changeTravelExpenseLocation, invoiceTypeNextStepAllowed, invoiceData}) => {

    const [selectedDayRange, setSelectedDayRange] = useState({
      from: null,
      to: null
    });


    const [countTravelDays, setCountTravelDays] = useState(0);
    const [startTime, setStartTime] = useState("08:00");
    const [endTime, setEndTime] = useState("18:00");
    const [durationDays, setDurationDays] = useState(0);

    React.useEffect(() => {

      let startDate = null,
      endDate = null;

      if(travelExpense.startDate !== null){
        const utcDateStart = new Date(travelExpense.startDate);
        const localDateStart = utcDateStart.toLocaleDateString();  // Format: MM/DD/YYYY (may vary based on locale)
        const localTimeStart = utcDateStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }); // Format: HH:mm (24-hour format)
        startDate = {year: utcDateStart.getFullYear(), month: utcDateStart.getMonth() +1, day: utcDateStart.getDate()}
        setStartTime(localTimeStart);
      }

      if(travelExpense.endDate !== null){
        const utcDateEnd = new Date(travelExpense.endDate);
        const localDateEnd = utcDateEnd.toLocaleDateString();  // Format: MM/DD/YYYY (may vary based on locale)
        const localTimeEnd = utcDateEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }); // Format: HH:mm (24-hour format)
        let endDate = {year: utcDateEnd.getFullYear(), month: utcDateEnd.getMonth() +1, day: utcDateEnd.getDate()} 
        setEndTime(localTimeEnd);
      }

      setSelectedDayRange({from: startDate, to: endDate})

      console.log(travelExpense.startDate);
    }, [travelExpense] )
    
    /*
    React.useEffect(() => {
        updateFromToDate()
    }, [isTravelExpenseOpen] )
    */

    const calculateDaysBetweenDates = async(startDateStr, endDateStr)=>{
      // Parse the date strings into Date objects
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);
    
      // Calculate the difference in time (in milliseconds)
      const differenceInTime = endDate.getTime() - startDate.getTime();
    
      // Convert the difference from milliseconds to days
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    
      // Include both start and end dates by adding 1
      return differenceInDays + 1;
    }

   const onChangeTime =async (e, key) => {
    console.log(e.currentTarget.value)
    let time = e.currentTarget.value.split(":");
    let startDate;
    if(key == "startDate"){
      startDate = new Date(Date.UTC(selectedDayRange.from.year, selectedDayRange.from.month -1, selectedDayRange.from.day));
      setStartTime(e.currentTarget.value);
    }else{
      startDate = new Date(Date.UTC(selectedDayRange.to.year, selectedDayRange.to.month -1, selectedDayRange.to.day));
      setEndTime(e.currentTarget.value);
    }
    startDate.setHours(time[0])
    startDate.setMinutes(time[1])
    changeText(key, startDate.toUTCString());

    
   }
    const changeInvoiceDate = async(newDate)=>{
      console.log(newDate);
        if(newDate.from != null && newDate.to != null){

            let utcDateEnd = new Date(Date.UTC(newDate.to.year, newDate.to.month -1, newDate.to.day));
            let utcDateStart = new Date(Date.UTC(newDate.from.year, newDate.from.month -1, newDate.from.day));
            changeText("startDate", utcDateStart.toISOString());
            changeText("endDate", utcDateEnd.toISOString());

            const oneDayInMilliseconds = 1000 * 60 * 60 * 24; // 1000 milliseconds * 60 seconds * 60 minutes * 24 hours
            const differenceInMilliseconds = utcDateEnd.getTime() - utcDateStart.getTime();
            const numberOfDays = Math.round(differenceInMilliseconds / oneDayInMilliseconds)+1;
            setCountTravelDays(numberOfDays);
            const mealsArray = [];

            for (let i = 0; i < numberOfDays; i++) {
              mealsArray.push({ breakfast: false, lunch: false, dinner: false });
            }
            changeText("meals", mealsArray)
        }
        setSelectedDayRange(newDate);
        
        /*
        const invoiceDate = new Date(newDate.year, newDate.month -1, newDate.day);
        invoiceDate.setHours(12);
        let openInvoiceData = newInvoice;
        if(createInvoice == false){
          openInvoiceData = rekeningData[selectedInvoice];
        }
        openInvoiceData.date = invoiceDate.toUTCString();
        saveInvoiceData(openInvoiceData);
        */
    }
  
    const renderCustomInput = ({ ref }) => (
        <Input
          readOnly
          ref={ref} // necessary
          style={{
            width: "100%"
          }}
          placeholder="Select the date range"
          value={selectedDayRange.from && selectedDayRange.to ? `${selectedDayRange.from.day}.${selectedDayRange.from.month}.${selectedDayRange.from.year} - ${selectedDayRange.to.day}.${selectedDayRange.to.month}.${selectedDayRange.to.year}` : ''}
        />
    )
  console.log("travel Expense",travelExpense);

  let invoiceCurrency = invoiceData.currency;

  if(invoiceCurrency == "EUR" || invoiceCurrency == "" || invoiceCurrency == undefined){
    invoiceCurrency = "€"
  }else if(invoiceCurrency == "USD"){
    invoiceCurrency = "$"
  }else if(invoiceCurrency == "JPY"){
    invoiceCurrency = "¥"
  }else if(invoiceCurrency == "GBP"){
    invoiceCurrency = "£"
  }else if(invoiceCurrency == "INR"){
    invoiceCurrency = "₹"
  }else if(invoiceCurrency == "CAD"){
    invoiceCurrency = "$"
  }
  
  return(
    <Box mt="2">
        <Divider mt="4"></Divider>
            <Heading mt="4" as="h3" size={"md"}>
              Details: {countTravelDays} Day(s)
            </Heading>
            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
              <GridItem colSpan={2}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Start & End Date</Text>
                    <DatePicker
                      renderInput={renderCustomInput} 
                      value={selectedDayRange}
                      onChange={changeInvoiceDate}
                      colorPrimary="#F39F50" 
                    ></DatePicker>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Start Time</Text>
                    <Input 
                      value={startTime}
                      onChange={(e)=>{onChangeTime(e, "startDate")}}
                      className="rekening-time-picker"
                      type="time"
                      isInvalid={editInvoiceErrorArray.includes("startDate")}
                      
                    ></Input>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>End Time</Text>
                    <Input 
                      value={endTime}
                      onChange={(e)=>{onChangeTime(e, "endDate")}}
                      className="rekening-time-picker"
                      type="time"
                      isInvalid={editInvoiceErrorArray.includes("endDate")}
                    ></Input>
                </Box>
              </GridItem>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Start Point</Text>
                        <GooglePlacesAutocomplete
                            apiKey={"AIzaSyDXHKiEW7UtIE2o21UDOCPMfunsIQUa3fA"}
                            selectProps={{
                              value: {label: travelExpense.locationFrom, value: {place_id: travelExpense.locationFromPlaceId,reference: "", description:travelExpense.locationFrom}},
                              onChange: (value)=>changeTravelExpenseLocation("locationFrom",value),
                              placeholder: "Please type"
                            }}
                            apiOptions={{ language: 'en' }}
                          />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Destination</Text>
                        <GooglePlacesAutocomplete
                            apiKey={"AIzaSyDXHKiEW7UtIE2o21UDOCPMfunsIQUa3fA"}
                            selectProps={{
                              value: {label: travelExpense.locationTo, value: {place_id: travelExpense.locationToPlaceId,reference: "", description:travelExpense.locationTo}},
                              onChange: (value)=>changeTravelExpenseLocation("locationTo",value),
                            }}
                            apiOptions={{ language: 'en' }}
                          />
                    </Box>
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Transportation</Text>
                        <Select 
                          value={travelExpense.transportation}
                          onChange={evt => changeText(`transportation`, evt.target.value)}
                          isInvalid={editInvoiceErrorArray.includes("transportation")}
                        >
                            <option value="train">Train</option>
                            <option value="plain">Plane</option>
                            <option value="own car">Own Car</option>
                            <option value="business car">Business Car</option>
                        </Select>
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Distance (km)</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.transportationDistance}
                            onChange={evt => changeText(`transportationDistance`, evt.target.value)}
                            isInvalid={editInvoiceErrorArray.includes("transportationDistance")}
                        />
                    </Box>
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
              <GridItem colSpan={2}>
                <Box mt="4">
                  <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Country of Destination</Text>
                  <Select
                    value={travelExpense.country}
                    onChange={evt => changeText(`country`, evt.target.value)}
                  >
                    {countryListAllowance.map((country)=>{
                      return(
                        <option key={country.code} value={country.code}>{country.name}</option>
                      )
                    })}
                    </Select>
                </Box>
              </GridItem>
              <Box mt="4">
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Arrival/Departure</Text>
                <Input readOnly={readOnlyAdminFields} onChange={evt => changeText(`allowanceSmall`, evt.target.value)} type="number" value={travelExpense.allowanceSmall}></Input>
              </Box>
              <Box mt="4">
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Full Day</Text>
                <Input readOnly={readOnlyAdminFields} onChange={evt => changeText(`allowanceBig`, evt.target.value)} type="number"  value={travelExpense.allowanceBig}></Input>
              </Box>
            </Grid>

            <Divider mt="4"></Divider>
            <Heading mt="4" as="h3" size={"md"}>
              <Tooltip label="The meals payed by your company" aria-label='The meals payed by your company'>
                  Provided Meals
              </Tooltip>
            </Heading>

            {"meals" in travelExpense && travelExpense.meals.map((singleMeal, dayIndex)=>{
                
                // Create a Date object from the startDate
                let mealDate = new Date(travelExpense.startDate);

                // Increase the date by 1 day
                mealDate.setDate(mealDate.getDate() + dayIndex);

                // Format the new date in 'de-DE' format and append "1 Tag"
                const formattedMealDate = mealDate.toLocaleDateString('de-DE');

                let marginTop = 3;
                let deductionBreakfast = parseFloat(travelExpense.allowanceBig)*0.2;
                let deductionLunch = parseFloat(travelExpense.allowanceBig)*0.4;
                let deductionDinner = parseFloat(travelExpense.allowanceBig)*0.4;
                let allowance = parseFloat(travelExpense.allowanceBig)
                
                if(dayIndex == 0){
                  allowance = parseFloat(travelExpense.allowanceSmall)
                }

                if(singleMeal.breakfast == false){
                  deductionBreakfast = 0;
                }
                if(singleMeal.lunch == false){
                  deductionLunch = 0;
                }
                if(singleMeal.dinner == false){
                  deductionDinner = 0;
                }

              return(
              <div>
                <Grid templateColumns='repeat(5, 1fr)' gap={3} marginTop="1">
                  <GridItem>
                    <Heading mt="4" as="h5" size={"sm"}>
                      {formattedMealDate}
                    </Heading>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Checkbox mt={marginTop} size='md' colorScheme='orange' 
                      isChecked={singleMeal.breakfast}
                      onChange={(e) => updateMeals(dayIndex, "breakfast",e.target.checked)}
                    >
                      Breakfast
                    </Checkbox>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Checkbox mt={marginTop} size='md' colorScheme='orange'
                      isChecked={singleMeal.lunch}
                      onChange={(e) => updateMeals(dayIndex, "lunch",e.target.checked)}
                    >
                      Lunch
                    </Checkbox>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Checkbox mt={marginTop} size='md' colorScheme='orange'
                      isChecked={singleMeal.dinner}
                      onChange={(e) => updateMeals(dayIndex, "dinner",e.target.checked)}
                    >
                      Dinner
                    </Checkbox>
                  </GridItem>
                </Grid>
                <Grid templateColumns='repeat(5, 1fr)' gap={3} marginTop="0">
                  <GridItem colSpan={1} mt="1">
                    <Text>
                      {allowance}€
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    {deductionBreakfast != 0 &&
                      <Text ml="6">
                      - {deductionBreakfast.toFixed(2)}{invoiceCurrency}
                      </Text>
                    }
                  </GridItem>
                  <GridItem colSpan={1}>
                    {deductionLunch != 0 &&
                      <Text ml="6">
                        - {deductionLunch.toFixed(2)}{invoiceCurrency}
                      </Text>
                    }
                  </GridItem>
                  <GridItem colSpan={1}>
                    {deductionDinner != 0 &&
                      <Text ml="6">
                        - {deductionDinner.toFixed(2)}{invoiceCurrency}
                    </Text>
                    }
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text>
                      = {Math.max(allowance - deductionBreakfast - deductionLunch - deductionDinner, 0).toFixed(2)}{invoiceCurrency}
                    </Text>
                  </GridItem>
              </Grid>
              </div>
              )
            })}
            {"meals" in travelExpense && 
            
            <Grid className="travelExpenseTotal" templateColumns='repeat(5, 1fr)' gap={3} marginTop="2">
                  <GridItem colSpan={1} mt="1">
                    <b>
                      <Text>
                      Total:
                      </Text>
                    </b>
                  </GridItem>
                  <GridItem colSpan={1}>
                  </GridItem>
                  <GridItem colSpan={1}>
                  </GridItem>
                  <GridItem colSpan={1}>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <b>
                      <Text>
                      = {invoiceData.total}{invoiceCurrency}
                      </Text>
                    </b>
                  </GridItem>
              </Grid>
            
            }
            {/*<Grid templateColumns='repeat(3, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Breakfasts</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.breakfasts}
                            type="number"
                            min="0"
                            max={countTravelDays}
                            onChange={evt => changeText(`breakfasts`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Lunches</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.lunches}
                            type="number"
                            min="0"
                            max={countTravelDays}
                            onChange={evt => changeText(`lunches`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Dinners</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.dinners}
                            type="number"
                            min="0"
                            max={countTravelDays}
                            onChange={evt => changeText(`dinners`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
            </Grid>*/}
    </Box>
  )
};

export default TravelExpensesElement;